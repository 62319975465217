import ApiHelper from '~/utils/ApiHelper'

interface PhoneNumber {
  number: string
}

export interface IProfileResponse {
  mobilePhone: PhoneNumber
  accountType: string
  profile: {
    email: string
  }
}

class GetProfile {
  async exec(): Promise<IProfileResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const path = `profile`
    const result = await apiHelper.get<IProfileResponse>(path)
    return result
  }
}

export default new GetProfile()
