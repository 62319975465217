import ApiHelper from '~/utils/ApiHelper'
import { ReferenceSourceType } from '~/types'

interface IConfirmChangeEmailResponse {
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }  
}

interface IConfirmEmailChangedRequest {
  code: string
  referenceSource: ReferenceSourceType | ''
  platform: string
}

class ConfirmSignup {
  async exec(payload: IConfirmEmailChangedRequest): Promise<IConfirmChangeEmailResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const path = 'authorization/webChangeEmailConfirm'
    const result = await apiHelper.post<IConfirmEmailChangedRequest, IConfirmChangeEmailResponse>(
      path,
      payload
    )
    return result
  }
}

export default new ConfirmSignup()
