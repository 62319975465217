<template>
  <svg
    data-v-989ccb70=""
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-v-53ec7f6b=""
  >
    <path
      d="M35.7834 35.0001H4.21677C3.91792 35.0086 3.62232 34.9363 3.36112 34.7909C3.09991 34.6455 2.88278 34.4322 2.73262 34.1737C2.58246 33.9152 2.50482 33.621 2.5079 33.322C2.51098 33.0231 2.59465 32.7305 2.7501 32.4751L18.5568 5.08344C18.7031 4.8301 18.9135 4.61972 19.1668 4.47345C19.4202 4.32719 19.7076 4.25018 20.0001 4.25018C20.2927 4.25018 20.58 4.32719 20.8334 4.47345C21.0868 4.61972 21.2972 4.8301 21.4434 5.08344L37.2501 32.4751C37.4056 32.7305 37.4892 33.0231 37.4923 33.322C37.4954 33.621 37.4177 33.9152 37.2676 34.1737C37.1174 34.4322 36.9003 34.6455 36.6391 34.7909C36.3779 34.9363 36.0823 35.0086 35.7834 35.0001Z"
      fill="url(#paint0_linear_20953_22955)"
    ></path>
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M35.7834 35.0001H4.21677C3.91792 35.0086 3.62232 34.9363 3.36112 34.7909C3.09991 34.6455 2.88278 34.4322 2.73262 34.1737C2.58246 33.9152 2.50482 33.621 2.5079 33.322C2.51098 33.0231 2.59465 32.7305 2.7501 32.4751L18.5568 5.08344C18.7031 4.8301 18.9135 4.61972 19.1668 4.47345C19.4202 4.32719 19.7076 4.25018 20.0001 4.25018C20.2927 4.25018 20.58 4.32719 20.8334 4.47345C21.0868 4.61972 21.2972 4.8301 21.4434 5.08344L37.2501 32.4751C37.4056 32.7305 37.4892 33.0231 37.4923 33.322C37.4954 33.621 37.4177 33.9152 37.2676 34.1737C37.1174 34.4322 36.9003 34.6455 36.6391 34.7909C36.3779 34.9363 36.0823 35.0086 35.7834 35.0001Z"
        fill="url(#paint1_linear_20953_22955)"
      ></path>
      <path
        opacity="0.5"
        d="M35.7834 35.0001H4.21677C3.91792 35.0086 3.62232 34.9363 3.36112 34.7909C3.09991 34.6455 2.88278 34.4322 2.73262 34.1737C2.58246 33.9152 2.50482 33.621 2.5079 33.322C2.51098 33.0231 2.59465 32.7305 2.7501 32.4751L18.5568 5.08344C18.7031 4.8301 18.9135 4.61972 19.1668 4.47345C19.4202 4.32719 19.7076 4.25018 20.0001 4.25018C20.2927 4.25018 20.58 4.32719 20.8334 4.47345C21.0868 4.61972 21.2972 4.8301 21.4434 5.08344L37.2501 32.4751C37.4056 32.7305 37.4892 33.0231 37.4923 33.322C37.4954 33.621 37.4177 33.9152 37.2676 34.1737C37.1174 34.4322 36.9003 34.6455 36.6391 34.7909C36.3779 34.9363 36.0823 35.0086 35.7834 35.0001Z"
        fill="url(#paint2_linear_20953_22955)"
      ></path>
      <path
        opacity="0.5"
        d="M35.7834 35.0001H4.21677C3.91792 35.0086 3.62232 34.9363 3.36112 34.7909C3.09991 34.6455 2.88278 34.4322 2.73262 34.1737C2.58246 33.9152 2.50482 33.621 2.5079 33.322C2.51098 33.0231 2.59465 32.7305 2.7501 32.4751L18.5568 5.08344C18.7031 4.8301 18.9135 4.61972 19.1668 4.47345C19.4202 4.32719 19.7076 4.25018 20.0001 4.25018C20.2927 4.25018 20.58 4.32719 20.8334 4.47345C21.0868 4.61972 21.2972 4.8301 21.4434 5.08344L37.2501 32.4751C37.4056 32.7305 37.4892 33.0231 37.4923 33.322C37.4954 33.621 37.4177 33.9152 37.2676 34.1737C37.1174 34.4322 36.9003 34.6455 36.6391 34.7909C36.3779 34.9363 36.0823 35.0086 35.7834 35.0001Z"
        fill="url(#paint3_linear_20953_22955)"
      ></path>
    </g>
    <path
      d="M21.6669 29.1666C21.6669 29.4962 21.5691 29.8184 21.386 30.0925C21.2028 30.3666 20.9425 30.5802 20.638 30.7064C20.3334 30.8325 19.9983 30.8655 19.675 30.8012C19.3517 30.7369 19.0547 30.5782 18.8217 30.3451C18.5886 30.112 18.4298 29.815 18.3655 29.4917C18.3012 29.1684 18.3342 28.8333 18.4604 28.5288C18.5865 28.2242 18.8001 27.9639 19.0742 27.7808C19.3483 27.5976 19.6705 27.4999 20.0002 27.4999C20.4422 27.4999 20.8661 27.6755 21.1787 27.9881C21.4913 28.3006 21.6669 28.7245 21.6669 29.1666ZM20.7718 14.1666H19.2285C19.1139 14.1663 19.0005 14.1896 18.8953 14.2352C18.7901 14.2807 18.6955 14.3474 18.6173 14.4312C18.5391 14.5149 18.479 14.6139 18.4408 14.722C18.4026 14.83 18.387 14.9448 18.3952 15.0591L19.1093 25.0591C19.1243 25.2697 19.2187 25.4667 19.3734 25.6104C19.5281 25.7541 19.7315 25.8338 19.9427 25.8332H20.0577C20.2688 25.8338 20.4723 25.7541 20.627 25.6104C20.7817 25.4667 20.876 25.2697 20.891 25.0591L21.6052 15.0591C21.6133 14.9448 21.5978 14.83 21.5596 14.722C21.5214 14.6139 21.4613 14.5149 21.3831 14.4312C21.3048 14.3474 21.2102 14.2807 21.105 14.2352C20.9999 14.1896 20.8864 14.1663 20.7718 14.1666Z"
      fill="url(#paint4_linear_20953_22955)"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_20953_22955"
        x1="20.0001"
        y1="4.25094"
        x2="20.0001"
        y2="35.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFD782"></stop>
        <stop offset="0.161" stop-color="#FED57C"></stop>
        <stop offset="0.372" stop-color="#FCCE6C"></stop>
        <stop offset="0.61" stop-color="#F9C351"></stop>
        <stop offset="0.867" stop-color="#F4B42B"></stop>
        <stop offset="1" stop-color="#F1AB15"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_20953_22955"
        x1="20.0001"
        y1="4.25094"
        x2="20.0001"
        y2="35.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFD375" stop-opacity="0"></stop>
        <stop offset="0.382" stop-color="#FFD374" stop-opacity="0.01"></stop>
        <stop offset="0.52" stop-color="#FED171" stop-opacity="0.039"></stop>
        <stop offset="0.619" stop-color="#FCCE6B" stop-opacity="0.092"></stop>
        <stop offset="0.698" stop-color="#FACA63" stop-opacity="0.167"></stop>
        <stop offset="0.766" stop-color="#F7C458" stop-opacity="0.265"></stop>
        <stop offset="0.826" stop-color="#F3BD4A" stop-opacity="0.387"></stop>
        <stop offset="0.881" stop-color="#EFB53B" stop-opacity="0.531"></stop>
        <stop offset="0.931" stop-color="#E9AC28" stop-opacity="0.7"></stop>
        <stop offset="0.975" stop-color="#E4A214" stop-opacity="0.884"></stop>
        <stop offset="1" stop-color="#E09B07"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_20953_22955"
        x1="127.928"
        y1="1280.92"
        x2="1163.37"
        y2="573.11"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFD375" stop-opacity="0"></stop>
        <stop offset="0.382" stop-color="#FFD374" stop-opacity="0.01"></stop>
        <stop offset="0.52" stop-color="#FED171" stop-opacity="0.039"></stop>
        <stop offset="0.619" stop-color="#FCCE6B" stop-opacity="0.092"></stop>
        <stop offset="0.698" stop-color="#FACA63" stop-opacity="0.167"></stop>
        <stop offset="0.766" stop-color="#F7C458" stop-opacity="0.265"></stop>
        <stop offset="0.826" stop-color="#F3BD4A" stop-opacity="0.387"></stop>
        <stop offset="0.881" stop-color="#EFB53B" stop-opacity="0.531"></stop>
        <stop offset="0.931" stop-color="#E9AC28" stop-opacity="0.7"></stop>
        <stop offset="0.975" stop-color="#E4A214" stop-opacity="0.884"></stop>
        <stop offset="1" stop-color="#E09B07"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_20953_22955"
        x1="1532.56"
        y1="1312.01"
        x2="511.31"
        y2="557.497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFD375" stop-opacity="0"></stop>
        <stop offset="0.382" stop-color="#FFD374" stop-opacity="0.01"></stop>
        <stop offset="0.52" stop-color="#FED171" stop-opacity="0.039"></stop>
        <stop offset="0.619" stop-color="#FCCE6B" stop-opacity="0.092"></stop>
        <stop offset="0.698" stop-color="#FACA63" stop-opacity="0.167"></stop>
        <stop offset="0.766" stop-color="#F7C458" stop-opacity="0.265"></stop>
        <stop offset="0.826" stop-color="#F3BD4A" stop-opacity="0.387"></stop>
        <stop offset="0.881" stop-color="#EFB53B" stop-opacity="0.531"></stop>
        <stop offset="0.931" stop-color="#E9AC28" stop-opacity="0.7"></stop>
        <stop offset="0.975" stop-color="#E4A214" stop-opacity="0.884"></stop>
        <stop offset="1" stop-color="#E09B07"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_20953_22955"
        x1="20.0002"
        y1="14.1666"
        x2="20.0002"
        y2="30.8332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4A4A4A"></stop>
        <stop offset="1" stop-color="#212121"></stop>
      </linearGradient>
    </defs>
  </svg>
</template>
