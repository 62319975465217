import ApiHelper from '~/utils/ApiHelper'

type PhoneNumberType = 'Mobile' | 'Landline'

export interface IPhoneNumberResponse {
  data: {
    type: PhoneNumberType
  }
  statusCode: string
}

class LookupPhoneNumber {
  async exec(phoneNumber: string): Promise<PhoneNumberType> {
    const apiHelper = new ApiHelper()
    const path = `twofactorauth/lookupPhoneNumber?phoneNumber=${phoneNumber.replace('+', '')}`
    const result = await apiHelper.get<IPhoneNumberResponse>(path)
    return result.data.type
  }
}

export default new LookupPhoneNumber()
