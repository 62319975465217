import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { navPaths } from '~/constants/navPaths'
import type { KnownFlows } from '~/types'
import { storeToRefs } from 'pinia'
import { platforms } from '~/constants/platforms'
import {
  formatProtocol,
  validateRedirectUrl,
  getRedirectQueryParams,
  getRedirectUriByPlatformAndPath,
} from '~/utils/redirectUrlHelper'
import { getPlatformByBrand } from './brandHelper'
import { canParse } from '~/helpers/url'
import Cookies from 'js-cookie'

const metaExists = (to: RouteLocationNormalized, metaName: KnownFlows) => {
  return to.matched.some((record) => record.meta?.[metaName])
}

export const continueAsGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const registerStore = useRegisterStore()
  const brandStore = useBrandStore()
  const ssoCookie = brandStore.brandSessionCookieName
  const envStore = useEnvStore()

  //Skip continueAsGuard check for /change-password
  if (to.path === '/change-password' || to.path.indexOf('twofactorauth') !== -1) {
    return
  }

  // Continue As flow does not apply to ERP
  if (registerStore.platform === platforms.ERP) {
    if (document.cookie.indexOf(ssoCookie) !== -1) {
      try {
        await registerStore.logout()
      } catch {
        // ignore logout error
      }
    }
    return
  }

  // Continue As only applies to initial page load
  if (from.fullPath !== '/' || to.redirectedFrom) {
    return
  }

  // in preprod Britline cookie will be in .xe.com domain and not be accessible here
  if (document.cookie.indexOf(ssoCookie) !== -1 || envStore.environment !== 'production') {
    try {
      await registerStore.getGSOTokens()
    } catch {
      const brandHostName = `.${brandStore.brandHostName}.com`

      // delete session cookie on error
      Cookies.remove(ssoCookie, {
        sameSite: envStore.environment === 'production' ? 'Lax' : 'None',
        secure: true,
        domain: brandHostName,
      })
    }
  }

  // logged in ERP user will have auth_code, all other users will have tokens
  if (registerStore.tokens?.access_token || registerStore.authCode) {
    // valid session - show continue as
    return { name: navPaths.CONTINUE }
  }
}

export const homeGuard = (to: RouteLocationNormalized) => {
  // signin is default route - assume redirect to Fxweb
  const registerStore = useRegisterStore()
  const envStore = useEnvStore()

  if (to.path === navPaths.HOME) {
    registerStore.setRedirectUrl(envStore.getEnvVariable('VUE_APP_URL_TRANSFER'))
    registerStore.setPlatform(getPlatformByBrand(useBrandStore().brandName))
    return { name: navPaths.SIGNIN }
  }
}

export const redirectUriGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  // set redirects only if this is a first page load and not a Continue As
  if (from.fullPath === '/' && to.fullPath !== '/continue') {
    setRedirectFromQueryParams(to.name)
    setOAuthValuesFromQueryParams()
  }
}

export const forgotPasswordGuard = (to: RouteLocationNormalized) => {
  const registerStore = useRegisterStore()
  const email = registerStore.email

  if (metaExists(to, 'forgotPasswordFlow') && !email && to.name !== navPaths.FORGOT_PASSWORD) {
    //If navigated directly to subsequent pages of forgot password flow, email won't be set
    return { name: navPaths.FORGOT_PASSWORD }
  }
}

export const signinGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const registerStore = useRegisterStore()
  const { email, password } = storeToRefs(registerStore)
  if (
    (metaExists(to, 'signinFlow') &&
      !email.value &&
      !password.value &&
      to.name !== navPaths.SIGNIN) ||
    (to.name === navPaths.ACCOUNT_TYPE && from.fullPath === '/')
  ) {
    return { name: navPaths.SIGNIN }
  }
}

export const signupEmailVerificationGuard = (to: RouteLocationNormalized) => {
  const registerStore = useRegisterStore()

  if (to.name === navPaths.SIGNUP_EMAIL_VERIFICATION && !registerStore.email) {
    return { name: navPaths.SIGNUP }
  }
}

export const twoFactorAuthGuard = async (to: RouteLocationNormalized) => {
  const twoFactorAuthStore = useTwoFactorAuthStore()

  // non 2FA page
  if (to.path.indexOf('twofactorauth') === -1) {
    return
  }

  if (twoFactorAuthStore.useWebLogin || twoFactorAuthStore.phoneNumber.length) {
    return
  }

  if (
    document.cookie.indexOf(useBrandStore().brandSessionCookieName) !== -1 ||
    useEnvStore().environment !== 'production'
  ) {
    try {
      await twoFactorAuthStore.getProfileData()
    } catch {
      return { name: navPaths.SIGNIN }
    }
    return
  }

  // not authenticated - sign in
  return { name: navPaths.SIGNIN }
}

/**
 * Redirect url after authentication is set based on the following priority:
 * - redirect_uri query parameter if supplied
 * - Registration/login url based on platform and current path
 * - FXWeb if no other value set
 */
const setRedirectFromQueryParams = (page: RouteRecordName | null | undefined) => {
  const registerStore = useRegisterStore()
  const envStore = useEnvStore()
  const queryParams = new URLSearchParams(window.location.search)
  const platform = queryParams.get('platform')
  const redirectParam = formatProtocol(queryParams.get('redirect_uri'))
  const redirectUrl = validateRedirectUrl(redirectParam)
  const redirectQueryParams = getRedirectQueryParams(redirectParam)

  // Since Continue As failed the previously used redirect_uri no longer applies
  registerStore.setRedirectUrl(null)

  if (platform) {
    registerStore.setPlatform(platform.toUpperCase())
  } else {
    // XERA login requires a platform, but logout redirect does not include one
    // derive platform from the redirect_uri in this case only
    const xeraUrl = envStore.getEnvVariable('VUE_APP_URL_XERA')
    if (xeraUrl.length && redirectUrl?.includes(xeraUrl)) {
      registerStore.setPlatform(platforms.XERA)
    } else {
      // default platform is XEMT/BRITLINE
      const platform = getPlatformByBrand(useBrandStore().brandName)
      registerStore.setPlatform(platform)
    }
  }

  const transferUrl = envStore.getEnvVariable('VUE_APP_URL_TRANSFER')

  if (redirectUrl && canParse(redirectUrl)) {
    registerStore.setReferenceSourceByUrl(redirectUrl)
    const url = new URL(redirectUrl)

    // redirect_uri sent from FXWeb may include a path that is not a valid login url e.g. /loggedout - just use the domain
    if (url.origin === transferUrl) {
      registerStore.setRedirectUrl(transferUrl)
    } else {
      registerStore.setRedirectUrl(redirectUrl)
    }

    if (redirectQueryParams) {
      registerStore.setRedirectQueryParams(redirectQueryParams)
    }
  }

  if (registerStore.redirectUrl) {
    return
  }

  // if we still have no redirect uri, try and derive it from page and platform
  registerStore.setRedirectUrl(getRedirectUriByPlatformAndPath(registerStore.platform, page))
}

//Extract OAuth values for query params and set it to store
//These values are only for Xe and aren't available for all platforms.
function setOAuthValuesFromQueryParams() {
  if (useBrandStore().brandName !== 'xe') {
    return
  }
  const registerStore = useRegisterStore()
  const queryParams = new URLSearchParams(window.location.search)
  registerStore.setOAuthState(queryParams.get('state'))
  registerStore.setOAuthStateCodeChallenge(queryParams.get('code_challenge'))
  registerStore.setOAuthStateCodeChallengeMethod(queryParams.get('code_challenge_method'))
}
