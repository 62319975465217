import { acceptHMRUpdate, defineStore } from 'pinia'
import type { UserType } from '~/types'

export const useNavStore = defineStore('nav', () => {
  const { t } = useI18n()
  const personalSteps = [
    {
      id: 0,
      title: t('ComponentRiaSidebar.ButtonAccount'),
      path: '/signup',
      pageTitle: t('createAccountPage.title'),
    },
    {
      id: 1,
      title: t('PageSendMoney.StepVerificationPageTitle'),
      path: '/verification',
      pageTitle: t('PageSendMoney.StepVerificationTitle'),
    },
    {
      id: 2,
      title: t('PageAccount.AccountSectionCountryLabel'),
      path: '/',
      pageTitle: t('ComponentCountryOfResidence.Title'),
    },
    {
      id: 3,
      title: t('PageRegister.StepInfoTitle'),
      path: '/',
      pageTitle: '',
    },
  ]
  const businessSteps = [
    {
      id: 0,
      title: t('ComponentRiaSidebar.ButtonAccount'),
      path: '/signup',
      pageTitle: t('createAccountPage.title'),
    },
    {
      id: 1,
      title: t('PageSendMoney.StepVerificationPageTitle'),
      path: '/verification',
      pageTitle: t('PageSendMoney.StepVerificationTitle'),
    },
    {
      id: 2,
      title: t('createAccountPage.business'),
      path: '/',
      pageTitle: t('createAccountPage.businessPageTitle'),
    },
    {
      id: 3,
      title: t('createAccountPage.personal'),
      path: '/',
      pageTitle: t('createAccountPage.personalPageTitle'),
    },
  ]
  type NavBarSteps = {
    activeStepId: number
    steps: {
      id: number
      title: string
      pageTitle: string
      path: string
    }[]
    withoutStepperPageTile: string
  }
  const navBarSteps = ref<NavBarSteps>({
    activeStepId: 0,
    steps: personalSteps,
    withoutStepperPageTile: '',
  })

  const redirectedFrom = ref('') //Strictly used for tracking from where the user were redirected to current page
  const showStepper = ref(true) //Display stepper in default layout. Layouts are selected at build time, but some pages may need to show/hide stepper at run time

  const withoutStepperPageTile = computed(() => navBarSteps.value.withoutStepperPageTile)
  const activeStepId = computed(() => navBarSteps.value.activeStepId)
  const steps = computed(() => navBarSteps.value.steps)
  const activeStep = computed(() => steps.value.filter((step) => step.id === activeStepId.value)[0])

  const location = computed(() => {
    if (redirectedFrom.value === 'signin') {
      return 'Sign in'
    } else if (redirectedFrom.value.startsWith('forgot-password')) {
      return 'Forgot password'
    } else {
      return 'Sign up'
    }
  })

  function setWithoutStepperPageTitle(value: string) {
    navBarSteps.value.withoutStepperPageTile = value
  }
  function setActiveStepId(id: number) {
    const idExist = steps.value.find((step) => step.id === id)
    if (idExist?.id !== undefined) {
      navBarSteps.value.activeStepId = id
    }
  }

  function getActiveStepBypath(path: string) {
    return steps.value.find((step) => step.path === path)
  }

  function setActiveStepByPath(path: string) {
    const step = getActiveStepBypath(path)
    if (step) {
      setActiveStepId(step.id)
    }
  }
  function setRedirectedFrom(path: string) {
    if (path) {
      redirectedFrom.value = path
    }
  }
  function setSteps(step: UserType) {
    if (step === 'Business') {
      navBarSteps.value.steps = businessSteps
    } else {
      navBarSteps.value.steps = personalSteps
    }
  }

  function setShowStepper(show: boolean) {
    showStepper.value = show
  }

  return {
    activeStepId,
    steps,
    setActiveStepId,
    activeStep,
    setActiveStepByPath,
    setWithoutStepperPageTitle,
    withoutStepperPageTile,
    redirectedFrom,
    showStepper,
    location,
    setRedirectedFrom,
    setSteps,
    setShowStepper,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot))
}
