import ApiHelper from '~/utils/ApiHelper'

export interface IResendCodeResponse {
  destination: string
  deliveryMedium: number
  attributeName: string
}

interface IResendCodeRequest {
}
export interface IResendCodeError {
  message: string
  code: string
  requestId: string
  retryable: boolean
  retryDelay: number
}

class CodeConfirmation {
  /**
   * Resends the 'confirm change of email' code that would have been sent when
   * dfxdesk changed an email via the 'admin change email' endpoint. The email has
   * already been changed in the profile service, this is really just to set
   * an 'email verified' flag in Cognito.
   * @param payload 
   * @param browserId 
   * @returns 
   */
  async exec(payload: IResendCodeRequest, browserId: string): Promise<IResendCodeResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    apiHelper.setHeader('browserId', browserId)
    apiHelper.setHeader('deviceId', 'integration_device_id')
    const path = 'authorization/webChangeEmailResend'

    const result = await apiHelper.post<IResendCodeRequest, IResendCodeResponse>(path, payload)
    return result
  }
}

export default new CodeConfirmation()
