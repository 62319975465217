import Cookies from 'js-cookie'
import { brandHostNames } from '~/constants/brands'

export default class ConsentManager {
  shouldShowConsentPrompt: boolean
  consentState: {
    compliance?: boolean
    marketing?: boolean
    performance?: boolean
  }
  onLoadCallbacks: (() => void)[]
  onUpdateCallbacks: (() => void)[]
  knownCookieCategories: string[]
  constructor() {
    this.shouldShowConsentPrompt = false
    this.consentState = {}
    this.onLoadCallbacks = []
    this.onUpdateCallbacks = []
    this.knownCookieCategories = ['performance', 'marketing', 'compliance']
  }

  /**
   * Initializes consent state based on whether or not the user is in a privacy-protected region.
   *
   * @param {boolean} isDefaultOptOut When false, this function will set the user's consent state to fully consented.
   */
  loadConsentState(isDefaultOptOut: boolean) {
    const previousSavedConsent = Cookies.get('xeConsentState')
    const queryParams = new URLSearchParams(window.location.search)
    const qsConsent = queryParams.get('showConsent')
    if ((previousSavedConsent === undefined && isDefaultOptOut) || qsConsent === 'true') {
      this.consentState = { performance: false, marketing: false, compliance: false }
      this.shouldShowConsentPrompt = true
    } else if (previousSavedConsent === undefined && !isDefaultOptOut) {
      this.consentState = { performance: true, marketing: true, compliance: false } // Note that compliance always true on transfer.xe.com.
    } else {
      const parsedSavedContent = previousSavedConsent ? JSON.parse(previousSavedConsent) : {}
      this.consentState = { ...parsedSavedContent, compliance: false }
    }

    for (const callback of this.onLoadCallbacks) {
      callback()
    }
  }

  /**
   * Checks if the user is consented a given category.
   *
   * @param {string} categoryName Name of the category
   * @returns {boolean} True if the user is consented to the given category, false otherwise.
   * @throws {Error} When attempting to check the consent status of an unrecognized category.
   */
  isConsentedToCategory(categoryName: string) {
    if (!this.knownCookieCategories.includes(categoryName)) {
      throw new Error(`Attempted to check consent status of unrecognized category: ${categoryName}`)
    }
    return this.consentState[categoryName as 'performance' | 'marketing' | 'compliance']
  }

  /**
   * Update the user's consent state.
   *
   * @param {object} consents An object that will be merged with the current consent state to update it.
   */
  updateConsentState(consents: object) {
    for (const [key, value] of Object.entries(consents)) {
      if (!this.knownCookieCategories.includes(key)) {
        throw new Error(`Tried to consent to an unknown category of cookies: ${key}`)
      } else if (typeof value !== 'boolean') {
        throw new Error(`Non-boolean consent value given for category ${key}`)
      }
    }

    this.consentState = { ...this.consentState, ...consents }

    let cookieDomain

    if (window.location.hostname.includes(brandHostNames.xe)) {
      cookieDomain = '.xe.com'
    } else if (window.location.hostname.includes(brandHostNames.britline)) {
      cookieDomain = '.cabips.com'
    } else {
      cookieDomain = window.location.hostname
    }

    Cookies.set('xeConsentState', JSON.stringify(this.consentState), {
      domain: cookieDomain,
      expires: 365,
    })

    for (const callback of this.onUpdateCallbacks) {
      callback()
    }
  }

  /**
   * Registers a function that will be called when consent state is initialized
   *
   * @param {Function} callback The callback. Note that it receives no arguments. Consent state is up to date when this callback is invoked.
   * @returns {Function} A function that can be called to stop listening for changes.
   */
  onLoad(callback: () => void) {
    this.onLoadCallbacks.push(callback)
    // remember that this works because of closures in JS :)
    const removalIndex = this.onLoadCallbacks.length - 1
    return () => {
      this.onLoadCallbacks.splice(removalIndex, 1)
    }
  }

  /**
   * Registers a function that will be called when the user causes changes to consent state
   *
   * @param {Function} callback The callback. Note that it receives no arguments. Consent state is up to date when this callback is invoked.
   * @returns {Function} A function that can be called to stop listening for consent updates.
   */
  onUpdate(callback: () => void) {
    this.onUpdateCallbacks.push(callback)
    // remember that this works because of closures in JS :)
    const removalIndex = this.onUpdateCallbacks.length - 1
    return () => {
      this.onUpdateCallbacks.splice(removalIndex, 1)
    }
  }
}
