<script setup lang="ts">
import { useBrandStore } from './stores/brand'
import { brands } from './constants/brands'
import { setPrismicTheme } from './helpers/prismic'
import xeLogo from './assets/images/xe-logo.svg'
import WebFont from 'webfontloader'
import { getUrl } from './constants/urls'
import { Link, Meta, useHead } from '@unhead/vue'
import { platforms } from './constants/platforms'

const brandStore = useBrandStore()

const brand = brandStore.brandName

const link = ref([]) as Ref<Array<Link>>
const meta = ref([]) as Ref<Array<Meta>>

// Content Security Policy
const CSP = useCSP(brand)

useHead({
  meta: () => {
    return [
      {
        'http-equiv': 'Content-Security-Policy',
        content: CSP,
      },
      ...meta.value,
    ]
  },
  link: () => link.value,
})

const isLoading = ref(false)
const router = useRouter()

const overrideEnvVariable = () => {
  const platform = useRegisterStore().platform
  if (platform === platforms.XECI) {
    const envStore = useEnvStore()
    //Override 'VUE_APP_URL_PUBLIC' URL with XECI public URL
    const xeciHomeUrl = envStore.getEnvVariable('VUE_APP_URL_XECI_PUBLIC')
    envStore.overrideEnvValue('VUE_APP_URL_PUBLIC', xeciHomeUrl)
  }
}
//Override env variable if needed once all the nav guards ran
overrideEnvVariable()

onBeforeMount(async () => {
  if (brand === brands.xe) {
    isLoading.value = true
    brandStore.setBrandLogo(xeLogo)
    brandStore.setOgImageUrl(getUrl('XeOgImageUrl'))
    WebFont.load({
      google: {
        families: ['Inter:400,500,700'],
      },
    })

    meta.value = [
      {
        name: 'msapplication-TileColor',
        content: '#0A146E',
      },
    ]
    link.value = [
      {
        rel: 'icon',
        href: '/favicons/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: '/favicons/apple-touch-icon.png',
        sizes: '180x180',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicons/favicon-32x32.png',
        sizes: '32x32',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicons/favicon-16x16.png',
        sizes: '16x16',
      },
      {
        rel: 'mask-icon',
        href: '/favicons/safari-pinned-tab.svg',
        color: '#0A146E',
      },
    ]
  } else {
    const theme = await setPrismicTheme(brand)
    isLoading.value = true

    meta.value = [
      {
        name: 'msapplication-TileColor',
        content: theme?.primaryColor,
      },
    ]

    link.value = [
      {
        rel: 'icon',
        href: theme?.favicons?.['favicon'],
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: theme?.favicons?.['apple-touch-icon'],
        sizes: '180x180',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: theme?.favicons?.['favicon-32x32'],
        sizes: '32x32',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: theme?.favicons?.['favicon-16x16'],
        sizes: '16x16',
      },
      {
        rel: 'mask-icon',
        href: theme?.favicons?.['safari-pinned-tab'],
        color: theme?.primaryColor,
      },
    ]
  }

  await router.isReady()
  isLoading.value = false
})

onMounted(() => {
  const analyticsStore = useAnalyticsStore()
  if (brand !== brands.britline) {
    // Send GTM event - 'Consent State Change'
    analyticsStore.gtmTrack({
      event: 'Consent State Change',
      variables: {
        isConsentedToPerformance: window.xeSession.consentManager?.consentState.performance,
        isConsentedToMarketing: window.xeSession.consentManager?.consentState.marketing,
        isConsentedToCompliance: window.xeSession.consentManager?.consentState.compliance,
      },
    })
  }
})
</script>

<template>
  <OenUiSpinnerBig v-if="isLoading" :loading="isLoading" />
  <RouterView />
  <CookieConsent />
</template>

<!-- Global styling -->
<style>
/* App level styling for OenUiAlert component's content */
.alert-message {
  @apply text-black text-sm;
}
.oen-ui-input-validation {
  @apply !text-red-text;
}
.link {
  @apply text-sm font-medium !important;
}

.input-label {
  @apply text-base font-medium !important;
}

.oen-ui-card {
  @apply !bg-[rgb(255,252,252)];
  @screen sm {
    @apply !bg-white;
  }

  .oen-ui-card-image-title {
    h2 {
      @apply text-2xl !important;
    }
  }

  /* Makes primary button inside card full-width with bg-blue */
  .oen-ui-button {
    &.oen-ui-button--primary {
      &:disabled:not(.button--loading) {
        @apply text-white bg-blue-button-disabled !important;
      }
    }

    .oen-ui-button-inner {
      transition: none;
    }
  }

  /* Override font-weight of OenUiLink component */
  .oen-ui-link {
    font-weight: 500;
  }
}

/* Match Xe logo size and position with Galileo */
.oen-ui-navbar-left .oen-ui-link img {
  min-width: 3rem;
  height: 2rem;
  margin-left: -8px;
  margin-top: -1px;
  @screen sm {
    margin: 0;
  }
}

.oen-ui-button {
  &.oen-ui-button--primary,
  &.oen-ui-button--secondary,
  &.oen-ui-button--text {
    @apply !w-full !rounded-none;
    @screen sm {
      @apply !rounded-lg;
    }
  }
}

.oen-ui-alert {
  text-align: left;
}
/* Adds margin to the li of Euronet's Inputdropdown component */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  @apply mb-1;
}

/* Disables recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

hr.line {
  @apply mt-6 mb-4 h-[0.0625rem] bg-[#f2f3f5] border-none;
}

/* Resets tailwind 'text' styling overwritten by Euronet's tailwind preset */
.text-xs {
  @apply leading-4;
}
.text-sm {
  @apply leading-5;
}
.text-base {
  @apply leading-6;
}
.text-lg {
  @apply leading-7;
}
.text-xl {
  @apply leading-7;
}
</style>
