class Logout {
  async exec(): Promise<unknown> {
    return await fetch(useEnvStore().launchpadApiUrl + '/logout?_account_site', {
      method: 'GET',
      credentials: 'include',
      redirect: 'manual',
    })
  }
}

export default new Logout()
