export function parse(urlString: string, base?: string) {
  try {
    return new URL(urlString, base)
  } catch (error) {
    return null
  }
}

export function canParse(string: string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
