

import __layout_0 from '/__w/account-site/account-site/src/layouts/default.vue'
export const layouts = {
'default': __layout_0,
'twoFactorAuth': () => import('/__w/account-site/account-site/src/layouts/twoFactorAuth.vue'),
'withoutStepper': () => import('/__w/account-site/account-site/src/layouts/withoutStepper.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
