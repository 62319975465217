import ApiHelper from '~/utils/ApiHelper'

interface IApiResponse {
  documents: {
    termsAndConditionsVersionAvailable: string
    privacyPolicyVersionAvailable: string
    cookieNoticeVersionAvailable: string
    documentLinks: {
      [x: string]: string
    }
  }
  formData: {
    moneyTransferCalculatorForm: {
      formFields: { id: string; options: unknown }[]
    }
  }
}

type Country = {
  value: string
  text: string
}

export interface IGetCountry {
  privacyVersion: string
  termsAndConditionsVersion: string
  termsAndConditionsUrl: string
  privacyPolicyUrl: string
  cookieNoticeUrl: string
  phoneCountries: Country[]
  countries: Country[]
}

class CountryApi {
  async exec(country: string, brandId: number): Promise<IGetCountry> {
    const apiHelper = new ApiHelper()
    const result = await apiHelper.get<IApiResponse>(`system/fields/${country}?brandId=${brandId}`)
    const privacyVersion = result.documents?.privacyPolicyVersionAvailable
    const termsAndConditionsVersion = result.documents.termsAndConditionsVersionAvailable
    const cookieNoticeVersion = result.documents.cookieNoticeVersionAvailable

    return {
      privacyVersion,
      termsAndConditionsVersion,
      termsAndConditionsUrl: result.documents.documentLinks[termsAndConditionsVersion],
      privacyPolicyUrl: result.documents.documentLinks[privacyVersion],
      cookieNoticeUrl: result.documents.documentLinks[cookieNoticeVersion],
      phoneCountries: result.formData.moneyTransferCalculatorForm.formFields?.find(
        (element) => element.id === 'phoneCountries'
      )?.options as Country[],
      countries: result.formData.moneyTransferCalculatorForm.formFields.find(
        (fields) => fields.id === 'Countries'
      )?.options as Country[],
    }
  }
}

export default new CountryApi()
