import { defineStore } from 'pinia'
import type { EventProperties, UserTraits } from '@segment/analytics-next'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { formatError } from '~/utils/formatError'
import { logError } from '~/utils/logger'
import Cookies from 'js-cookie'
import { SEGMENT_EVENTS } from '~/constants/segmentAnalytics'
import LogRocket from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'

type Analytics = {
  performanceConsent: boolean
  marketingConsent: boolean
  complianceConsent: false
  segmentAnalytic: AnalyticsBrowser | null
  logRocketSessionUrl: string | null
}

interface IRequest {
  reqId: string
  url: string
  headers: { [key: string]: string | undefined }
  body: string
  method: string
  referrer?: string
  mode?: string
  credentials?: string
}

export const useAnalyticsStore = defineStore('analytics', () => {
  const analytics = ref<Analytics>({
    segmentAnalytic: null,
    logRocketSessionUrl: null,
    complianceConsent: false,
    marketingConsent: false,
    performanceConsent: false,
  })

  const privateFieldNames = [
    'username',
    'password',
    'country',
    'tcVersion',
    'sessionId',
    'token',
    'name',
    'email',
    'userId',
  ]

  /*************  Getters ******* */
  const logRocketSessionUrl = computed(() => analytics.value.logRocketSessionUrl)
  const marketingConsent = computed(() => analytics.value.marketingConsent)
  const performanceConsent = computed(() => analytics.value.performanceConsent)
  const complianceConsent = computed(() => analytics.value.complianceConsent)

  /************* Actions  ******* */
  function initSegment(): void {
    const token = useEnvStore().segmentKey

    analytics.value.segmentAnalytic = AnalyticsBrowser.load({ writeKey: token })
    analytics.value.segmentAnalytic.catch((error) => 'Failed to load segment' + error)
    // Set Segment's Anonymous Id to Amplitude's DeviceId if present
    // getting the amplitude DeviceId from amp_xxx cookie
    // assumed (as per amplitude's doc) always the cookie name starts with amp_
    // we always picked up the first amp_xxx cookie
    let ampDeviceId = null
    const localCookies = Cookies.get()
    const cookieName = Object.keys(localCookies).find((x) => x.indexOf('amp_') > -1)
    if (cookieName) {
      ampDeviceId = localCookies[cookieName].split('.')[0]
    }
    if (ampDeviceId) {
      analytics.value.segmentAnalytic.setAnonymousId(ampDeviceId)
    }
  }

  async function identify({ traits = {} }: { traits?: UserTraits }) {
    const allTraits = {
      isConsentedToPerformance: analytics.value.performanceConsent,
      isConsentedToMarketing: analytics.value.marketingConsent,
      isConsentedToCompliance: analytics.value.complianceConsent,
      product: SEGMENT_EVENTS.PRODUCT_NAME,
      releaseInfo: useEnvStore().appVersion,
      version: useRegisterStore().segmentVersionType,
      brand: useBrandStore().brandName,
      userLanguage: useRegisterStore().userLocale,
      userLanguageCode: useRegisterStore().preferredLanguage,
      userLanguageCountryCode: useRegisterStore().preferredLanguage.toUpperCase(),
      ...traits,
    }
    if (useRegisterStore().email) {
      allTraits['email'] = useRegisterStore().email
    }

    const userId = useRegisterStore().profileId?.toString()

    try {
      // if Segment script is blocked by browser, identify promise will not settle
      await Promise.race([
        analytics.value.segmentAnalytic?.identify(userId, allTraits),
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error('timeout after 5s'))
          }, 5000)
        }),
      ])
    } catch (exception) {
      const error = formatError(exception)
      logError('Failed to send Segment Identify: ' + error.message, 'store/analytics.identify')
    }
  }

  async function track({
    eventName,
    properties = {},
  }: {
    eventName: string
    properties?: EventProperties
  }) {
    const segmentReferenceSource = useRegisterStore().referenceSource
    properties['brand'] = useBrandStore().brandName

    if (segmentReferenceSource) {
      properties['referenceSource'] = segmentReferenceSource
    }
    if (useRegisterStore().email) {
      properties['email'] = useRegisterStore().email
    }
    if (analytics.value.logRocketSessionUrl) {
      properties['logRocketSessionURL'] = analytics.value?.logRocketSessionUrl
    }
    try {
      // if Segment script is blocked by browser, track promise will not settle
      await Promise.race([
        analytics.value.segmentAnalytic?.track(eventName, properties),
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error('timeout after 5s'))
          }, 5000)
        }),
      ])
    } catch (exception) {
      const error = formatError(exception)
      logError('Failed to send Segment track: ' + error.message, eventName)
    }
  }

  async function page(pageName: string) {
    analytics.value.segmentAnalytic?.page(pageName).catch((exception) => {
      const error = formatError(exception)
      logError("Failed to send segment's page " + error.message, pageName)
    })
  }

  async function gtmTrack({ event, variables }: { event: string; variables: any }) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: event, ...variables })
  }

  function changeConsent({
    marketingConsent,
    performanceConsent,
  }: Pick<Analytics, 'marketingConsent' | 'performanceConsent'>) {
    analytics.value.marketingConsent = marketingConsent
    analytics.value.performanceConsent = performanceConsent
  }

  async function init(usePrevConsentCookie): Promise<void> {
    initSegment()
    let performanceConsentValue: boolean
    performanceConsentValue = false
    if (usePrevConsentCookie) {
      const previousSavedConsent = Cookies.get('xeConsentState')
      if (previousSavedConsent !== undefined) {
        performanceConsentValue = JSON.parse(previousSavedConsent)['performance']
      }
    } else {
      performanceConsentValue = performanceConsent.value
    }
    if (useEnvStore().getEnvVariable('VUE_APP_LOGROCKET_ENABLED') && performanceConsentValue) {
      initLogRocket()
      await fetchLogRocketSessionUrl()
    } else {
      console.log('LogRocket disabled')
    }
  }

  async function fetchLogRocketSessionUrl() {
    LogRocket.getSessionURL((sessionUrl) => {
      analytics.value.logRocketSessionUrl = sessionUrl
      track({
        eventName: 'LogRocket',
        properties: {
          //email: useAuthStore().lastLogin,
        },
      })
    })
  }

  function initLogRocket() {
    const envStore = useEnvStore()

    let settings
    if (envStore.environment === 'development') {
      settings = {
        release: envStore.appVersion + '-develop',
        dom: {
          isEnabled: true, //disable|enable all DOM recording
          inputSanitizer: false, // obfuscate all user-input elements <select> and <input>
        },
      }
    } else {
      const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([
        ...privateFieldNames,
      ])

      settings = {
        release: envStore.appVersion,
        shouldCaptureIP: false,
        network: {
          requestSanitizer: sanitizeRequest(requestSanitizer),
          responseSanitizer: sanitizeResponse(responseSanitizer),
        },
        dom: {
          isEnabled: true, //disable|enable all DOM recording
          inputSanitizer: true, // obfuscate all user-input elements <select> and <input>
        },
      }
    }

    LogRocket.init(envStore.getEnvVariable('VUE_APP_KEYS_LOGROCKET_KEY'), settings)
    console.log('LogRocket enabled')
  }

  function sanitizeData(keyValue: { [prop: string]: string }, key: string) {
    if (key !== 'undefined') {
      privateFieldNames.forEach((p) => {
        if (key.toLowerCase().indexOf(p) !== -1) {
          keyValue[key] = '*'
        }
      })
    }
    return keyValue
  }

  function sanitizeHeader(network: IRequest) {
    if (network.headers) {
      for (const key in network.headers) {
        const header = key.toLowerCase()
        if (header === 'authorization' || header === 'bearer') {
          network.headers[key] = '*'
        }
      }
    }
  }

  function sanitizeRequest(requestSanitizer: (request: IRequest) => unknown) {
    return (request: IRequest) => {
      sanitizeHeader(request)

      const url = request?.url?.toLowerCase() || ''
      if (url.indexOf('/system') !== -1 || (url.indexOf('segment') !== -1 && !request.body)) {
        return request
      } else if (url.indexOf('segment') !== -1) {
        const kv = JSON.parse(request.body)
        const propArray = ['traits', 'properties']
        propArray.forEach((tp) => {
          let ptvalue = kv[tp]
          if (ptvalue !== 'undefined') {
            for (const ele in ptvalue) {
              ptvalue = sanitizeData(ptvalue, ele)
              kv.value = JSON.stringify(ptvalue)
            }
          }
        })
        request.body = JSON.stringify(kv)

        return request
      } else {
        requestSanitizer(request)
        return request
      }
    }
  }

  function sanitizeResponse(responseSanitizer: (response: IRequest) => unknown) {
    return (response: IRequest) => {
      sanitizeHeader(response)
      if (response?.url?.toLowerCase().indexOf('/system') !== -1) {
        return response
      } else {
        responseSanitizer(response)
        return response
      }
    }
  }

  return {
    marketingConsent,
    performanceConsent,
    complianceConsent,
    logRocketSessionUrl,
    init,
    identify,
    track,
    page,
    changeConsent,
    gtmTrack,
  }
})
