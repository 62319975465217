import { storeToRefs } from 'pinia'
import { SEGMENT_EVENTS } from '~/constants/segmentAnalytics'
import { ReferenceSourceType } from '~/types'

export function useSegmentReferenceSource() {
  const referenceSource = ref<ReferenceSourceType | ''>('')
  const registerStore = useRegisterStore()
  const { accountType } = storeToRefs(registerStore)

  function getReferenceSource(accountType: string): ReferenceSourceType | '' {
    switch (accountType) {
      case 'Business':
        return SEGMENT_EVENTS.referenceSource.BUSINESS
      case 'Personal':
        return SEGMENT_EVENTS.referenceSource.PERSONAL
      case 'XECD':
        return SEGMENT_EVENTS.referenceSource.XECD
      case 'XERA':
        return SEGMENT_EVENTS.referenceSource.XERA
      default:
        return ''
    }
  }

  referenceSource.value = getReferenceSource(accountType.value)
  return referenceSource
}
