import ApiHelper from '~/utils/ApiHelper'

export interface IUserInfoResponse {
  email: string
}

class GetUserInfo {
  async exec(): Promise<IUserInfoResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const path = 'oauth2/userInfo'
    const result = await apiHelper.get<IUserInfoResponse>(path)
    return result
  }
}

export default new GetUserInfo()
