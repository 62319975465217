import ApiHelper from '~/utils/ApiHelper'
import { useRegisterStore } from '~/stores/register'

interface ISignupResponse {
  destination: string
  deliveryMedium: number
  attributeName: string
}

interface ISignupRequest {
  username: string
  password: string
  country: string
  tcVersion: string
  referenceSource: string
  captcha: string | undefined
  devicePlatform: string
  platform: string
  preferredLanguage: string
  hcaptcha: string | undefined
}

export type ISignupError = {
  code: 'UsernameExistsException'
  isSuccessStatusCode?: boolean
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }
}

class Signup {
  async exec(request: ISignupRequest, deviceId: string): Promise<ISignupResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    apiHelper.setHeader('deviceId', deviceId)
    const token = await window.AwsWafIntegration.getToken()
    apiHelper.setHeader('x-aws-waf-token', token)
    const result = await apiHelper.post<ISignupRequest, ISignupResponse>(
      useRegisterStore().accountType === 'Business'
        ? 'authorization/websignupcorp'
        : 'authorization/websignup',
      request
    )
    return result
  }
}

export default new Signup()
