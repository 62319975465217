import type { TwoFactorAuthChannel } from '~/types'
import ApiHelper from '~/utils/ApiHelper'

export interface ILinkedAccount {
  profileId: number
  firstName: string
  lastName: string
  clientNumber: string
  mobileNumber: {
    mobilePrefix: string
    mobilePhone: string
  }
  accountType: 'Consumer' | 'Corporate'
}
export interface ISignInResponse {
  authenticationResult: {
    success: boolean
    code: string
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
  }
  sessionId?: string
  customerProfile: {
    id: number
    accountType: string
    unitcode: string
    twoFANumber: {
      number: string
    }
  }
  linkedAccounts?: ILinkedAccount[]
  authCode?: string
  require2FA?: boolean
  twoFactorAuthStatus?: string
  accountType: string
}

interface ISignInRequest {
  username: string
  password: string
  devicePlatform: string
  captcha?: string
  platform: string
  redirectUrl: string | null
  referenceSource?: string
  codeChallenge?: string
  codeChallengeMethod?: string
  preferredLanguage: string
  helpcentre?: boolean
  hcaptcha?: string
  channel?: TwoFactorAuthChannel
  twoFANumber?: string
  verifyCode?: string
  rememberBrowser?: boolean
  changeTwoFANumber?: boolean
  twoFAProfileId?: number
}

class SignIn {
  async exec(payload: ISignInRequest): Promise<ISignInResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const result = await apiHelper.post<ISignInRequest, ISignInResponse>(
      'authorization/weblogin',
      payload
    )
    return result
  }
}

export default new SignIn()
