import { ValidationRules } from '~/helpers/validatePassword'
import ApiHelper from '~/utils/ApiHelper'

interface IPasswordValidationResponse {
  requirements: ValidationRules[]
}

class PasswordValidation {
  async exec(): Promise<IPasswordValidationResponse> {
    const apiHelper = new ApiHelper()
    const path = 'authorization/passwordRequirements'
    const result = await apiHelper.get<IPasswordValidationResponse>(path)
    return result
  }
}

export default new PasswordValidation()
