<script setup lang="ts">
import { SEGMENT_EVENTS } from '~/constants/segmentAnalytics'

const registerStore = useRegisterStore(),
  systemFieldStore = useSystemFieldStore(),
  analyticStore = useAnalyticsStore()

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
})

const emits = defineEmits(['update:modelValue'])
const { modelValue } = useVModel(props, emits)

const publicUrl = useEnvStore().getEnvVariable('VUE_APP_URL_PUBLIC')

//Fire up on PAGE_LOAD segment event;event should be fired once modal is displayed
//This Modal will be shown only if modelValue is true; hence firing up segment event once modelValue is true
watch(
  () => modelValue.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return
    }
    if (newValue === true) {
      analyticStore.track({
        eventName: SEGMENT_EVENTS.leaveRegistration.PAGE_LOAD,
      })
    }
  }
)
const leaveRegistration = async () => {
  await analyticStore.track({
    eventName: SEGMENT_EVENTS.leaveRegistration.CONFIRMED,
  })
  //Reset store before redirection
  registerStore.resetRegister()
  systemFieldStore.resetSystemField()
  window.location.href = publicUrl
}
const cancel = () => {
  analyticStore.track({
    eventName: SEGMENT_EVENTS.leaveRegistration.CANCELLED,
  })
}
</script>

<template>
  <XeInformationModal v-model="modelValue" title="" @confirm="leaveRegistration" @cancel="cancel">
    <template #modalHeader>
      <OenUiModalHeader>
        <IconWarning />
      </OenUiModalHeader>
    </template>

    <div class="text-center">
      <h1 class="font-medium text-black text-2xl">
        <slot name="header">
          {{ $t('component.XeLeaveRegistrationModal.title') }}
        </slot>
      </h1>
      <p>
        <slot>
          {{ $t('component.XeLeaveRegistrationModal.modalText') }}
        </slot>
      </p>
    </div>

    <template #footer="{ confirm, cancel }">
      <OenUiButton @click="confirm">{{
        $t('component.XeLeaveRegistrationModal.confirmText')
      }}</OenUiButton>
      <OenUiButton theme="secondary" class="!border-none" @click="cancel">{{
        $t('component.XeLeaveRegistrationModal.cancelText')
      }}</OenUiButton>
    </template>
  </XeInformationModal>
</template>
