import Cookies from 'js-cookie'
import { DEFAULT_LOCALE, LOCALE_COOKIE } from '~/constants/locales'

//Get list of supported locales
//Get user preferred locales:
//1. Read the locales from the cookie 'NEXT_LOCALE'
//2. get the user preferred locales from the browser
//If the supported locales contains the user preferred locales
//If the supported locales doesn't contain the user preferred locales, use closely locales
//Else default locales to 'en-GB'

type LocalesPath = Record<string, () => Promise<{ default: Record<string, string> }>>

export function initLocale(): {
  locale: string
  localePath: () => Promise<{ default: Record<string, string> }>
} {
  const localesPath = getPathForLocales()
  const localesList = Object.keys(localesPath)
  const guessedLocale = guessUserLocale(localesList)
  setLocaleToCookie(guessedLocale.split('-')[0])
  const userLocalePath = localesPath[guessedLocale]
  return {
    locale: guessedLocale,
    localePath: userLocalePath,
  }
}

function getPathForLocales(): LocalesPath {
  const localesMap = Object.fromEntries(
    Object.entries(import.meta.glob('../locales/*.json')).map(([path, loadLocale]) => [
      transformLocaleName(path),
      loadLocale,
    ])
  )
  return localesMap as LocalesPath
}
function transformLocaleName(path: string) {
  let matchedPath = path.match(/(\w*)\.json$/)?.[1]
  matchedPath = matchedPath?.replace('_', '-')
  return matchedPath
}
const getCloseLocale = (localeList: readonly string[], searchValue: string) =>
  localeList.find((locale) => locale.includes(searchValue))

const getExactMatchLocale = (localeList: readonly string[], searchValue: string) =>
  localeList.find((locale) => locale.toLowerCase() === searchValue.toLowerCase())

export function guessUserLocale(localesList: string[]): string {
  const localeFromCookie = getLocaleFromCookie(localesList, LOCALE_COOKIE)
  if (localeFromCookie) {
    return localeFromCookie
  }
  const userLocales = window.navigator.languages
  const supportedLocale = localeLookup(localesList, userLocales)

  return supportedLocale ?? DEFAULT_LOCALE
}

//Example: Locale to Match 'en-US'
// 1. Look for 'en-US' if supported list contains exact locale, return that locale
// 2. Look for 'en' if supported list contains exact locale, return that locale
// 3. Look for any locale including 'en', e.g. 'en-GB' return the first locale containing the lang 'en'
// 4. Else Return null

function localeLookup(
  localeList: readonly string[],
  userLocales: readonly string[]
): string | null {
  for (const locale of userLocales) {
    const supportedLocale = getExactMatchLocale(localeList, locale)
    if (supportedLocale) {
      return supportedLocale
    }
    const language = locale.split('-')[0]
    const supportedLanguage = getExactMatchLocale(localeList, language)

    if (supportedLanguage) {
      return supportedLanguage
    }
    const closeLocale = getCloseLocale(localeList, language)
    if (closeLocale) {
      return closeLocale
    }
  }
  return null
}

function getLocaleFromCookie(localesList: string[], cookieName: string): string | null {
  const localeFromCookie = Cookies.get(cookieName)
  if (!localeFromCookie) {
    return null
  }
  //Check if the locale value in the cookie is supported by app
  const locale = getCloseLocale(localesList, localeFromCookie)
  return locale ?? null
}
function setLocaleToCookie(locale: string) {
  const hostName = window.location.hostname
  let domain = ''
  if (hostName.includes('xe')) {
    domain = '.xe.com'
  } else if (hostName.includes('cabips')) {
    domain = '.cabips.com'
  } else {
    domain = window.location.hostname
  }
  Cookies.set(LOCALE_COOKIE, locale, { domain: domain, expires: 365 })
}
