<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    Required: false,
    default: false,
  },

  title: {
    type: String,
    default: '',
  },
})
type Emits = (e: 'update:modelValue' | 'cancel' | 'confirm') => void

const emits = defineEmits(["update:modelValue", "cancel", "confirm"])
const { modelValue } = useVModel(props, emits)
</script>

<template>
  <div class="information-model">
    <OenUiModal
      v-model="modelValue"
      :title="title"
      @cancel="emits('cancel')"
      @confirm="emits('confirm')"
    >
      <template #header>
        <div class="modal-header">
          <slot name="modalHeader" />
        </div>
      </template>

      <div class="modal-body">
        <slot />
      </div>
      <template #footer="{ confirm, cancel }">
        <OenUiModalFooter class="footer">
          <slot name="footer" class="button-group" :confirm="confirm" :cancel="cancel" />
        </OenUiModalFooter>
      </template>
    </OenUiModal>
  </div>
</template>

<style scoped>
.modal-body {
  :deep h1 {
    @apply mb-4 font-medium text-black text-2xl text-center;
  }
  :deep p {
    @screen sm {
      max-width: 344px;
    }
  }
}
.footer {
  @apply flex flex-col-reverse justify-between gap-6;
  .button.button--secondary {
    @apply mt-4 !important;
  }
  :deep(.button) {
    @apply mt-2  rounded-lg w-full;
    &:disabled:not(.button--loading) {
      @apply text-white bg-blue-button-disabled;
    }
  }
}
</style>
