<script setup>
const navStore = useNavStore()
const router = useRouter()
const mq = reactive(useMediaQuery())

const leaveRegistration = ref(false)
const publicUrl = useEnvStore().getEnvVariable('VUE_APP_URL_PUBLIC')
const brandStore = useBrandStore()
const brandLogo = computed(() => brandStore.brandLogo)

const showLeaveRegistrationModal = () => {
  leaveRegistration.value = true
}
</script>

<template>
  <OenUiNavBar>
    <XeLeaveRegistrationModal v-model="leaveRegistration" />
    <template #left>
      <OenUiLink
        v-if="mq.current !== 'xs'"
        :href="publicUrl"
        :aria-label="`${brandStore.brandName} Logo`"
      >
        <img :src="brandLogo" :alt="`${brandStore.brandName} Logo`" />
      </OenUiLink>
      <OenUiButton
        v-if="mq.current === 'xs' && navStore.activeStepId > 0"
        theme="icon"
        @click="router.go(-1)"
      >
        <ICarbonChevronLeft />
      </OenUiButton>
      <OenUiLink
        v-else-if="mq.current === 'xs' && navStore.activeStepId === 0"
        :href="publicUrl"
        :aria-label="`${brandStore.brandName} Logo`"
      >
        <img :src="brandLogo" :alt="`${brandStore.brandName} Logo`" />
      </OenUiLink>
    </template>

    <template v-if="mq.current === 'xs'">
      {{ navStore.activeStep.pageTitle }}
    </template>
    <OenUiNavBarStepper :steps="navStore.steps" :active-step-idx="navStore.activeStepId" />

    <template #right>
      <OenUiButton theme="icon" @click="showLeaveRegistrationModal">
        <IRmt4Close name="close" />
      </OenUiButton>
    </template>
  </OenUiNavBar>

  <div class="flex flex-col items-center text-justify xs:gap-5 sm:gap-12">
    <RouterView />
  </div>
</template>

<style scoped>
.navbar {
  @screen sm {
    @apply mb-4 border-b-0;
  }
}
</style>
