export const brands = {
  xe: 'xe',
  johnLewis: 'johnlewis',
  britline: 'britline',
} as const

export const brandHostNames = {
  xe: 'xe',
  jlp: 'jlp',
  britline: 'cabips',
} as const

export const brandIds = {
  xe: 8,
  jlp: 6,
  britline: 7,
} as const

export const brandDisplayNames = {
  xe: 'Xe',
  britline: 'BIPS',
  jlp: 'JLFX',
}

export const brandSessionCookieNames = {
  xe: 'xe_sso_id_launchpad',
  britline: 'britline_sso_id_launchpad',
  jlp: 'jlp_sso_id_launchpad',
}
