import { acceptHMRUpdate, defineStore } from 'pinia'
import { each } from 'lodash-es'
import axios from 'axios'
import { logError } from '~/utils/logger'
import { formatError } from '~/utils/formatError'
import { SEGMENT_EVENTS } from '~/constants/segmentAnalytics'
import { brands } from '~/constants/brands'
import { parse } from '~/helpers/url'
import type { ReferenceSourceType } from '~/types'

type ConfigKeys = {
  VUE_APP_ENDPOINTS_LAUNCHPADAPI: string
  VUE_APP_USE_HCAPTCHA: string
  VUE_APP_XE_VERSION: string
  VUE_APP_ENV: string
  VUE_APP_RECAPTCHA_PUBLIC_KEY: string
  VUE_APP_HCAPTCHA_PUBLIC_KEY: string
  VUE_APP_KEYS_SEGMENT: string
  VUE_APP_KEYS_LOGROCKET_KEY: string
  VUE_APP_LOGROCKET_ENABLED: string
  VUE_APP_URL_CORPORATE_REGISTRATION: string
  VUE_APP_URL_PERSONAL_REGISTRATION: string
  VUE_APP_URL_XERA: string
  VUE_APP_URL_XECD: string
  VUE_APP_URL_TRANSFER: string
  VUE_APP_URL_GALILEO: string
  VUE_APP_URL_XECI: string
  VUE_APP_PAGE_TITLE_PREFIX: string
  VUE_APP_URL_PUBLIC: string
  VUE_APP_URL_HELP_CENTRE_ACCOUNT_CLOSED: string
  VUE_APP_URL_HELP_CENTRE_CREATE_ACCOUNT: string
  VUE_APP_HELPCENTRE_URL: string
  VUE_APP_HELPCENTRE_LOGIN: string
  VUE_APP_URL_XECI_PUBLIC: string
}
const configKeys: ConfigKeys = {
  VUE_APP_ENDPOINTS_LAUNCHPADAPI: 'VUE_APP_ENDPOINTS_LAUNCHPADAPI',
  VUE_APP_USE_HCAPTCHA: 'VUE_APP_USE_HCAPTCHA',
  VUE_APP_XE_VERSION: 'VUE_APP_XE_VERSION',
  VUE_APP_ENV: 'VUE_APP_ENV',
  VUE_APP_RECAPTCHA_PUBLIC_KEY: 'VUE_APP_RECAPTCHA_PUBLIC_KEY',
  VUE_APP_HCAPTCHA_PUBLIC_KEY: 'VUE_APP_HCAPTCHA_PUBLIC_KEY',
  VUE_APP_KEYS_SEGMENT: 'VUE_APP_KEYS_SEGMENT',
  VUE_APP_KEYS_LOGROCKET_KEY: 'VUE_APP_KEYS_LOGROCKET_KEY',
  VUE_APP_LOGROCKET_ENABLED: 'VUE_APP_LOGROCKET_ENABLED',
  VUE_APP_URL_CORPORATE_REGISTRATION: 'VUE_APP_URL_CORPORATE_REGISTRATION',
  VUE_APP_URL_PERSONAL_REGISTRATION: 'VUE_APP_URL_PERSONAL_REGISTRATION',
  VUE_APP_URL_XERA: 'VUE_APP_URL_XERA',
  VUE_APP_URL_XECD: 'VUE_APP_URL_XECD',
  VUE_APP_URL_TRANSFER: 'VUE_APP_URL_TRANSFER',
  VUE_APP_URL_GALILEO: 'VUE_APP_URL_GALILEO',
  VUE_APP_URL_XECI: 'VUE_APP_XECI',
  VUE_APP_PAGE_TITLE_PREFIX: 'VUE_APP_PAGE_TITLE_PREFIX',
  VUE_APP_URL_PUBLIC: 'VUE_APP_URL_PUBLIC',
  VUE_APP_URL_HELP_CENTRE_ACCOUNT_CLOSED: 'VUE_APP_URL_HELP_CENTRE_ACCOUNT_CLOSED',
  VUE_APP_URL_HELP_CENTRE_CREATE_ACCOUNT: 'VUE_APP_URL_HELP_CENTRE_CREATE_ACCOUNT',
  VUE_APP_HELPCENTRE_URL: 'VUE_APP_HELPCENTRE_URL',
  VUE_APP_HELPCENTRE_LOGIN: 'VUE_APP_HELPCENTRE_LOGIN',
  VUE_APP_URL_XECI_PUBLIC: 'VUE_APP_URL_XECI_PUBLIC',
}

export const useEnvStore = defineStore('env', () => {
  const envVariables: { [x: string]: string } = reactive({})

  //getters
  const launchpadApiUrl = computed(() => envVariables?.VUE_APP_ENDPOINTS_LAUNCHPADAPI)
  const environment = computed(() => envVariables?.VUE_APP_ENV)
  const captchaKey = computed(() => envVariables?.VUE_APP_RECAPTCHA_PUBLIC_KEY)
  const hcaptchaKey = computed(() => envVariables?.VUE_APP_HCAPTCHA_PUBLIC_KEY)
  const segmentKey = computed(() => envVariables?.VUE_APP_KEYS_SEGMENT)
  const logRocketKey = computed(() => envVariables?.VUE_APP_KEYS_LOGROCKET_KEY)
  const logRocketEnabled = computed(() => envVariables?.VUE_APP_LOGROCKET_ENABLED)
  const appVersion = computed(() => envVariables?.VUE_APP_XE_VERSION)
  const useHCaptcha = computed(() => envVariables?.VUE_APP_USE_HCAPTCHA)

  // Actions
  const setVariables = (variables: { [x: string]: string }) => {
    each(configKeys, (_: string, key: string) => {
      const match = variables[key]
      if (match === undefined || match === null) {
        logError('ENV: Variable not defined for this environment: ' + key, 'store/env.setVariables')
      } else {
        envVariables[key] = match
      }
    })
  }

  const fetchVariables = async () => {
    try {
      const brandStore = useBrandStore()
      const { data } = await axios.get(`/env/config.json`)
      //Get Brand info before fetching any env files
      brandStore.getBrandFromDomain(data.default)
      const brand = brandStore.brandName
      brandStore.getBrandIdFromBrand(brand)
      let envVariables = data.default
      if (brand === brands.britline) {
        envVariables = { ...envVariables, ...data?.britline }
      } else if (brand === brands.johnLewis) {
        envVariables = { ...envVariables, ...data?.jlp }
      } else {
        envVariables = { ...envVariables, ...data.xe }
      }

      setVariables(envVariables)
    } catch (ex) {
      const error = formatError(ex)
      logError('ENV: Failed to fetch variables: ' + error.message, 'store/env.fetchVariables')
    }
  }

  const overrideEnvValue = (key: keyof ConfigKeys, value: string) => {
    if (key in configKeys) {
      envVariables[key] = value
    }
  }
  const init = async () => {
    // env variables are used by systemFiledStore & registerStore, hence,sequential await
    await fetchVariables()
    await Promise.all([useSystemFieldStore().init(), useRegisterStore().init()])
    useAnalyticsStore().init(true)
  }
  function getEnvVariable(value: keyof ConfigKeys) {
    return envVariables?.[value]
  }

  function getReferenceSourceFromRedirectURL(redirectURI: string): ReferenceSourceType | '' {
    let referenceSource = ''
    const url = parse(redirectURI)

    if (!url) {
      return ''
    }

    const origin = url.origin
    switch (origin) {
      case envVariables.VUE_APP_URL_CORPORATE_REGISTRATION:
        referenceSource = SEGMENT_EVENTS.referenceSource.BUSINESS
        break
      case envVariables.VUE_APP_URL_PERSONAL_REGISTRATION:
        referenceSource = SEGMENT_EVENTS.referenceSource.PERSONAL
        break
      case envVariables.VUE_APP_URL_XECD:
        referenceSource = SEGMENT_EVENTS.referenceSource.XECD
        break
      case parse(envVariables.VUE_APP_URL_XECI)?.origin:
        referenceSource = SEGMENT_EVENTS.referenceSource.XECI
        break
      case envVariables.VUE_APP_URL_XERA:
        referenceSource = SEGMENT_EVENTS.referenceSource.XERA
        break
      default:
        break
    }

    return referenceSource as ReferenceSourceType
  }

  return {
    init,
    launchpadApiUrl,
    environment,
    captchaKey,
    hcaptchaKey,
    useHCaptcha,
    segmentKey,
    logRocketKey,
    logRocketEnabled,
    appVersion,
    getEnvVariable,
    getReferenceSourceFromRedirectURL,
    overrideEnvValue,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEnvStore, import.meta.hot))
}
