import axios from 'axios'

export type ApiError<T> = {
  message: string
  statusCode?: number
  response?: Response<T>
  stack?: string
}

type Response<T> = {
  [Property in keyof T]: T[Property]
}

export const formatError = <T>(error: unknown): ApiError<T> => {
  const apiError: ApiError<T> = {
    message: 'Unknown error',
  }

  if (axios.isAxiosError(error)) {
    const response = error?.response
    const request = error?.request
    apiError.message = error.message
    apiError.stack = error.stack

    if (response?.data) {
      apiError.statusCode = response.status
      apiError.response = {
        ...response.data,
      } as Response<T>
    }
    if (request) {
      apiError.message = error.message
    }
  } else if (error instanceof Error) {
    apiError.message = error.message
    apiError.stack = error.stack
  } else {
    apiError.message = String(error)
  }
  return apiError
}
