import ApiHelper from '~/utils/ApiHelper'

export interface IGSOTokensResponse {
  tokens: {
    access_token: string
    refresh_token: string
    id_token: string
  }
  auth_code?: string
}

class GetGSOTokens {
  async exec(profileId?: number): Promise<IGSOTokensResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    let path = 'getGSOTokens'
    if (profileId) {
      path += '?profile_id=' + profileId
    }
    const result = await apiHelper.get<IGSOTokensResponse>(path)
    return result
  }
}

export default new GetGSOTokens()
