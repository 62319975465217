import axios, { AxiosHeaderValue, AxiosInstance, AxiosRequestConfig, Method } from 'axios'

export default class ApiHelper {
  private client: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    this.client = this.createClient(config)
  }

  protected createClient(config?: AxiosRequestConfig): AxiosInstance {
    const client = axios.create(config)
    const envStore = useEnvStore()
    client.defaults.baseURL = envStore.launchpadApiUrl
    client.defaults.withCredentials = config?.withCredentials

    return client
  }

  setHeader(key: string, value: AxiosHeaderValue) {
    this.client.defaults.headers.common[key] = value
  }
  removeHeader(key: string) {
    delete this.client.defaults.headers.common[key]
  }

  // ******************HTTP METHODS ******************************
  post<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    return this.request<TRequest, TResponse>(path, 'POST', payload)
  }

  get<TResponse>(path: string): Promise<TResponse> {
    return this.request<null, TResponse>(path, 'GET')
  }

  put<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    return this.request<TRequest, TResponse>(path, 'PUT', payload)
  }

  patch<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse> {
    return this.request<TRequest, TResponse>(path, 'PATCH', payload)
  }

  private async request<TRequest, TResponse>(
    path: string,
    httpMethod: Method,
    payload?: TRequest
  ): Promise<TResponse> {
    // query param for traceablility in ELK
    path += (path.includes('?') ? '&' : '?') + '_account_site'

    const response = await this.client.request<TResponse>({
      method: httpMethod,
      url: path,
      data: payload,
    })
    return response.data
  }
}
