const Urls = {
  XE_PRIVACY_POLICY: 'https://help.xe.com/hc/articles/19592734245009',
  XE_COOKIE_POLICY: 'https://help.xe.com/hc/articles/4408642862865',

  GOOGLE_PRIVACY_POLICY: 'https://policies.google.com/privacy',
  GOOGLE_TERMS_CONDITIONS: 'https://policies.google.com/terms',

  XECI_PRIVACY_POLICY: 'https://help.xe.com/hc/sections/16736362205585',
  XECI_TERMS_CONDITIONS: 'https://help.xe.com/hc/articles/23435480909201',

  ZA_SIKHONA: 'https://www.riasikhona.co.za/xe2',

  CIFAS: 'https://cifas.org.uk/fpn',
  XeOgImageUrl:
    'https://images.ctfassets.net/izij9rskv5y1/1aqbDa0nay8CVodSY9ZANt/bd3e8480e9426b38b5bb9d8f843dc64e/xe_consumer_logo.jpg',
} as const

export function getUrl(key: keyof typeof Urls) {
  return Urls[key] ?? ''
}
