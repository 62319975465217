export const SEGMENT_EVENTS = {
  PRODUCT_NAME: 'Login UI',
  referenceSource: {
    PERSONAL: 'XEMT Personal',
    BUSINESS: 'XEMT Business',
    XECD: 'XECD',
    XERA: 'XERA',
    XECI: 'XECI',
  },
  leaveRegistration: {
    PAGE_LOAD: 'Leave Registration Popup Displayed',
    CONFIRMED: 'Leave Registration Confirmed',
    CANCELLED: 'Leave Registration Cancelled',
  },
  signup: {
    PAGE_LOAD: 'Account Registration Started',
    ACCOUNT_CREATED: 'Account Created',
    EMAIL_EXISTS: 'Email Address Already In Use',
    ACCOUNT_TYPE: 'Account Type Changed',
  },
  emailVerification: {
    PAGE_LOAD: 'Email Verification Started',
    EMAIL_VERIFIED: 'Email Verified',
    INVALID_CODE: 'Invalid Email Verification Code Entered',
    EXPIRED_CODE: 'Expired Email Verification Code Entered',
    RESEND_CODE: 'Email Verification Code Resend Requested',
  },
  signIn: {
    PAGE_LOAD: 'Login Accessed',
    LOGIN_STARTED: 'Login Started',
    LOGIN_METHOD: 'email',
    LOGIN_SUCCESSFUL: 'Login Successful',
    LOGIN_ERROR_ACCOUNT_CLOSED: 'Account Closed',
    LOGIN_ERROR_ACCOUNT_LOCKED: 'Account Locked',
    LOGIN_ERROR_UNKNOWN: 'Something Went Wrong',
    LOGIN_LOCATION: 'Sign in',
    LOGIN_FAILED: 'Login Failed',
  },
  forgotPassword: {
    PAGE_LOAD: 'Password Recovery Started',
    EMAIL_ENTERED: 'Password Recovery Email Entered',
    CODE_ENTERED: 'Password Recovery Code Entered',
    NEW_PASSWORD_ENTERED: 'Password Recovery New Password Entered',
    PASSWORD_RECOVERY_COMPLETED: 'Password Recovery Completed',
    RESEND_CODE_REQUESTED: 'Password Recovery Code Resend Requested',
  },
  notFound: {
    PAGE_LOAD: 'Page Not Found',
  },
  continueAs: {
    PAGE_LOAD: 'Continue as displayed',
    CONFIRMED: 'Continue as user',
    CANCELLED: 'Do not continue as user',
  },
  changePassword: {
    PAGE_LOAD: 'Change Password Started',
    LOCATION: 'Settings',
    CHANGE_PASSWORD_FAILED: 'Change Password Failed',
    FORM_SUBMITTED: 'Change Password Credentials Submitted',
  },
  accountSelection: {
    PAGE_LOAD: 'Account Type Started',
    ACCOUNT_SELECTED: 'Account Type Selected',
  },
  captcha: {
    CHALLENGE_STARTED: 'Captcha Challenge Started',
    CHALLENGE_FINISHED: 'Captcha Challenge Finished',
    CHALLENGE_FAILED: 'Captcha Challenge Failed',
  },
  twoFactorAuth: {
    REQUIRED: '2FA Required',
    STARTED: '2FA Setup Started',
    SELECTION_COMPLETED: '2FA Selection Completed',
    CODE_SENT: '2FA Code Sent',
    CODE_ENTERED: '2FA Code Entered',
    CODE_SUBMITTED: '2FA Code Submitted',
    RESEND_REQUESTED: '2FA Code Resend Requested',
    CHANGE_NUMBER: '2FA Change Phone Number',
    FAILURE: '2FA Failure',
    SETUP_PROCESSING: '2FA Setup Processing',
    SUCCESS: '2FA Successful',
    CHANGE_NUMBER_STARTED: 'Change 2FA Phone Number Started',
    CHANGE_NUMBER_RESEND: '2FA Email Verification Code Resend Requested',
    CHANGE_NUMBER_COMPLETED: 'Change 2FA Phone Number Completed',
  },
} as const
