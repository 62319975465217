import ApiHelper from '~/utils/ApiHelper'
import type { ReferenceSourceType } from '~/types'

interface IConfirmSignupResponse {
  authenticationResult: {
    success: boolean
    message?: string
    errors: string[]
    cognitoError?: {
      message: string
      code: string
      statusCode: number
    }
    code?:
      | 'CognitoCodeMismatchException'
      | 'ExpiredCodeException'
      | 'CognitoExpiredCodeException'
      | 'CognitoLimitExceededException'
      | 'CognitoTooManyFailedAttemptsException'
      | 'CognitoTooManyRequestsException'
      | 'CognitoNotAuthorizedException'
  }
  authToken: {
    jwtToken: string
    expiredInSeconds: number
  }
  sessionId?: string
  authCode?: string
}

interface IConfirmSignupRequest {
  username: string
  password: string
  code: string
  deviceId: string
  deviceToken: string
  devicePlatform: string
  referenceSource: ReferenceSourceType | ''
  platform: string
  redirectUrl: string | null
  location?: string
  codeChallenge: string
  codeChallengeMethod: string
  preferredLanguage: string
}

class ConfirmSignup {
  async exec(payload: IConfirmSignupRequest): Promise<IConfirmSignupResponse> {
    const apiHelper = new ApiHelper({
      withCredentials: true,
    })
    const path = 'authorization/confirmSignup'
    const result = await apiHelper.post<IConfirmSignupRequest, IConfirmSignupResponse>(
      path,
      payload
    )
    return result
  }
}

export default new ConfirmSignup()
