import { acceptHMRUpdate, defineStore } from 'pinia'
import type { RemovableRef } from '@vueuse/core'
import { useSessionStorage } from '@vueuse/core'
import countryApi from '~/api/launchpad/systemFields/country/get'
import { formatError } from '~/utils/formatError'
import { logError } from '~/utils/logger'

type PhoneCountry = {
  text: string // 'Belgium (+32)'
  value: string // value: '32'
  id: number // id: 1105
  nameAbbr: string // nameAbbr: 'BE'
  valueKey?: string
}

type SystemField = {
  geoLocation: string
  termsAndConditionsUrl: RemovableRef<string | undefined>
  documents: {
    privacyPolicyVersion: string | undefined
    termsAndConditionsVersion: string | undefined
    privacyPolicyUrl: string | undefined
    cookieNoticeUrl: string | undefined
  }
  phoneCountries: PhoneCountry[]
}

export const useSystemFieldStore = defineStore('systemField', () => {
  const systemField = ref<SystemField>({
    geoLocation: 'GB',
    termsAndConditionsUrl: useSessionStorage('termsAndConditionsUrl', ''),
    documents: {
      privacyPolicyVersion: '',
      termsAndConditionsVersion: '',
      privacyPolicyUrl: '',
      cookieNoticeUrl: '',
    },
    phoneCountries: [],
  })

  /*************  Getters ******* */

  const privacyPolicyUrl = computed(() => systemField.value.documents.privacyPolicyUrl)
  const termsAndConditionsUrl = computed(() => systemField.value.termsAndConditionsUrl)
  const cookieNoticeUrl = computed(() => systemField.value.documents.cookieNoticeUrl)
  const geoLocation = computed(() => systemField.value.geoLocation)
  const termsAndConditionsVersion = computed(
    () => systemField.value.documents.termsAndConditionsVersion
  )
  const phoneCountries = computed(() => systemField.value.phoneCountries)

  /************* Actions  ******* */

  function resetSystemField() {
    systemField.value.documents = {
      privacyPolicyUrl: undefined,
      termsAndConditionsVersion: undefined,
      privacyPolicyVersion: undefined,
      cookieNoticeUrl: undefined,
    }
    systemField.value.termsAndConditionsUrl = undefined
  }

  async function fetchSystemDocs() {
    try {
      const searchQuery = 'GB'
      const {
        privacyVersion,
        termsAndConditionsUrl,
        privacyPolicyUrl,
        termsAndConditionsVersion,
        cookieNoticeUrl,
        countries,
        phoneCountries,
      } = await countryApi.exec(searchQuery, useBrandStore().brandId)

      systemField.value.documents = {
        termsAndConditionsVersion: termsAndConditionsVersion,
        privacyPolicyVersion: privacyVersion,
        privacyPolicyUrl: privacyPolicyUrl,
        cookieNoticeUrl: cookieNoticeUrl,
      }
      systemField.value.termsAndConditionsUrl = termsAndConditionsUrl

      const countryMap = {} as { [key: string]: string }
      if (countries) {
        countries.forEach((country) => {
          countryMap[country.value] = country.text
        })
      }

      const phoneCountriesMap = phoneCountries.map((country, index) => {
        return {
          text: '+' + country.text + ' - ' + countryMap[country.value],
          value: country.text,
          valueKey: country.value,
          nameAbbr: country.value,
          id: index,
        }
      })
      systemField.value.phoneCountries = phoneCountriesMap
    } catch (ex) {
      const error = formatError(ex)
      logError(
        'Failed to get document for country ,' + error.message,
        'store/systemField.fetchSystemDocs'
      )
    }
  }

  async function init() {
    await fetchSystemDocs()
  }

  return {
    privacyPolicyUrl,
    termsAndConditionsUrl,
    termsAndConditionsVersion,
    geoLocation,
    fetchSystemDocs,
    init,
    resetSystemField,
    cookieNoticeUrl,
    phoneCountries,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSystemFieldStore, import.meta.hot))
}
