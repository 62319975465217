import { acceptHMRUpdate, defineStore } from 'pinia'
import parsePhoneNumber from 'libphonenumber-js'
import Send2FA from '~/api/launchpad/authorization/send2FA/post'
import Validate2FA from '~/api/launchpad/authorization/validate2FA/post'
import GetProfile from '~/api/launchpad/profile/get'
import GetLookupPhoneNumber from '~/api/launchpad/twofactorauth/lookupPhoneNumber/get'
import type { TwoFactorAuthChannel } from '~/types'

interface TwoFactor {
  channel: TwoFactorAuthChannel | undefined
  required: boolean
  phoneNumber: string
  phoneNumberIsLandline: boolean
  phoneNumberIsValid: boolean
  rememberBrowser: boolean
  sendCodeAttempts: number
  verificationAttempts: number
  useWebLogin: boolean
  twoFactorAuthStatus: string
  twoFAProfileId?: number
}

export const useTwoFactorAuthStore = defineStore('twoFactorAuth', () => {
  const twoFactor = reactive<TwoFactor>({
    channel: 'sms',
    required: false,
    phoneNumber: '',
    phoneNumberIsLandline: false,
    phoneNumberIsValid: false,
    rememberBrowser: false,
    sendCodeAttempts: 0,
    verificationAttempts: 0,
    useWebLogin: false,
    twoFactorAuthStatus: '',
  })

  //getters
  const channel = computed(() => twoFactor.channel)
  const required = computed(() => twoFactor.required)
  const phoneNumber = computed(() => twoFactor.phoneNumber)
  const phoneNumberIsLandline = computed(() => twoFactor.phoneNumberIsLandline)
  const phoneNumberIsValid = computed(() => twoFactor.phoneNumberIsValid)
  const formattedPhoneNumber = computed(() => {
    if (twoFactor.phoneNumber === '') {
      return ''
    }
    const parsedNumber = parsePhoneNumber(twoFactor.phoneNumber)

    return parsedNumber?.formatInternational()
  })
  const phoneNumberCountryCode = computed(() => {
    if (twoFactor.phoneNumber === '') {
      return ''
    }
    return parsePhoneNumber(twoFactor.phoneNumber)?.country
  })
  const rememberBrowser = computed(() => twoFactor.rememberBrowser)
  const channelEventText = computed(() => {
    switch (channel.value) {
      case 'sms':
        return 'SMS'
      case 'call':
        return 'Call'
      case 'whatsapp':
        return 'WhatsApp'
      default:
        return ''
    }
  })
  const sendCodeAttempts = computed(() => twoFactor.sendCodeAttempts)
  const verificationAttempts = computed(() => twoFactor.verificationAttempts)
  const useWebLogin = computed(() => twoFactor.useWebLogin)
  const twoFactorAuthStatus = computed(() => twoFactor.twoFactorAuthStatus)

  //Actions
  const setRequired = (required: boolean) => {
    twoFactor.required = required
  }

  const setRememberBrowser = (rememberBrowser: boolean) => {
    twoFactor.rememberBrowser = rememberBrowser
  }

  const send2FACode = async (
    channel: TwoFactorAuthChannel,
    changePhoneNumber?: boolean,
    captchaValue?: string,
    hcaptchaValue?: string
  ) => {
    twoFactor.channel = channel

    if (twoFactor.useWebLogin) {
      const result = await useRegisterStore().signinWith2FA(
        twoFactor.channel,
        twoFactor.phoneNumber,
        undefined,
        undefined,
        changePhoneNumber,
        twoFactor.twoFAProfileId,
        captchaValue,
        hcaptchaValue
      )
      return result
    } else {
      const result = await Send2FA.exec({
        channel: twoFactor.channel,
        phoneNumber: twoFactor.phoneNumber,
        changeTwoFANumber: changePhoneNumber,
      })
      return result
    }
  }

  const verify2FACode = async (
    code: string,
    phoneNumber?: string,
    changePhoneNumber?: boolean,
    captchaValue?: string,
    hcaptchaValue?: string
  ) => {
    if (twoFactor.useWebLogin) {
      const result = await useRegisterStore().signinWith2FA(
        phoneNumber ? 'email' : undefined,
        phoneNumber,
        code,
        twoFactor.rememberBrowser,
        changePhoneNumber,
        undefined,
        captchaValue,
        hcaptchaValue
      )
      return result
    } else {
      const result = await Validate2FA.exec({
        code: code,
        rememberBrowser: twoFactor.rememberBrowser,
        changeTwoFANumber: changePhoneNumber,
        twoFANumber: phoneNumber,
      })

      return result
    }
  }

  const getProfileData = async () => {
    const registerStore = useRegisterStore()
    const profile = await GetProfile.exec()

    setPhoneNumber(profile.mobilePhone.number)
    await lookupPhoneNumber(profile.mobilePhone.number)
    registerStore.setAccountType(profile.accountType === 'Corporate' ? 'Business' : 'Personal')
    registerStore.setEmail(profile.profile.email)
  }

  const setPhoneNumber = (phoneNumber: string) => {
    twoFactor.phoneNumber = phoneNumber
  }

  const lookupPhoneNumber = async (phoneNumber: string) => {
    try {
      const phoneNumberType = await GetLookupPhoneNumber.exec(phoneNumber)
      twoFactor.phoneNumberIsLandline = phoneNumberType === 'Landline'
      twoFactor.phoneNumberIsValid = true
      return true
    } catch {
      twoFactor.phoneNumberIsValid = false
      return false
    }
  }
  const setTwoFactorAuthStatus = async (twoFactorAuthStatus: string) => {
    twoFactor.twoFactorAuthStatus = twoFactorAuthStatus
  }

  const incrementSendCodeAttempts = () => {
    twoFactor.sendCodeAttempts++
    return twoFactor.sendCodeAttempts
  }

  const incrementVerificationAttempts = () => {
    twoFactor.verificationAttempts++
    return twoFactor.verificationAttempts
  }

  const setUseWebLogin = (useWebLogin: boolean) => {
    twoFactor.useWebLogin = useWebLogin
  }

  const setTwoFAProfileId = (profileId: number) => {
    twoFactor.twoFAProfileId = profileId
  }

  return {
    channel,
    required,
    phoneNumber,
    phoneNumberIsLandline,
    phoneNumberIsValid,
    formattedPhoneNumber,
    phoneNumberCountryCode,
    rememberBrowser,
    channelEventText,
    sendCodeAttempts,
    verificationAttempts,
    useWebLogin,
    twoFactorAuthStatus,
    setRequired,
    setRememberBrowser,
    send2FACode,
    verify2FACode,
    getProfileData,
    setPhoneNumber,
    lookupPhoneNumber,
    incrementSendCodeAttempts,
    incrementVerificationAttempts,
    setUseWebLogin,
    setTwoFactorAuthStatus,
    setTwoFAProfileId,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandStore, import.meta.hot))
}
