export const navPaths = {
  SIGNUP: 'signup',
  CONTINUE: 'continue',
  SIGNIN: 'signin',
  FORGOT_PASSWORD: 'forgot-password',
  SIGNUP_EMAIL_VERIFICATION: 'signup-email-verification',
  NOT_FOUND: 'notfound',
  HOME: '/',
  ACCOUNT_TYPE: 'account-type',
} as const
