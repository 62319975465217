import { acceptHMRUpdate, defineStore } from 'pinia'
import { BrandName, BrandId } from '~/types'
import {
  getBrandDisplayName,
  getBrandHostName,
  getBrandName,
  getBrandSessionCookieName,
} from '~/utils/brandHelper'
import { brands, brandIds } from '~/constants/brands'

type Brand = {
  name: BrandName
  id: BrandId
  logo: string
  ogImageUrl: string | null // open graph image Url  https://ogp.me/
}
export const useBrandStore = defineStore('brand', () => {
  const brand = reactive<Brand>({
    name: 'xe',
    id: 8,
    logo: '',
    ogImageUrl: null,
  })

  //getters
  const brandName = computed(() => brand.name)
  const brandId = computed(() => brand.id)
  const brandLogo = computed(() => brand.logo)
  const brandDisplayName = computed(() => getBrandDisplayName(brand.name))
  const brandOgImageUrl = computed(() => brand.ogImageUrl)
  const brandSessionCookieName = computed(() => getBrandSessionCookieName(brand.name))
  const brandHostName = computed(() => getBrandHostName(brand.name))

  //Actions
  const getBrandFromDomain = (config: { [x: string]: string }) => {
    const brandName = getBrandName(config)
    brand.name = brandName
  }
  const getBrandIdFromBrand = (brandName: string) => {
    if (brandName.includes(brands.britline)) {
      brand.id = brandIds.britline
    } else if (brandName.includes(brands.johnLewis)) {
      brand.id = brandIds.jlp
    } else if (brandName.includes(brands.xe)) {
      brand.id = brandIds.xe
    } else {
      brand.id = brandIds.xe
    }
    return brand.id
  }
  const setBrandLogo = (logoUrl: string) => {
    brand.logo = logoUrl
  }

  const setOgImageUrl = (url: string) => {
    //If og image is present in prismic, ogImageUrl will be set to URL available in prismic
    if (url) {
      brand.ogImageUrl = url
    }
  }

  return {
    getBrandFromDomain,
    setBrandLogo,
    brandName,
    getBrandIdFromBrand,
    brandId,
    brandLogo,
    brandDisplayName,
    setOgImageUrl,
    brandOgImageUrl,
    brandSessionCookieName,
    brandHostName,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandStore, import.meta.hot))
}
